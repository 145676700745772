<template>
    <!-- IMAGE MODAL -->
    <Modal version="6" @close="modalActive=false" :modalActive="imagePoiSelectorOn">
        <div class="dark:bg-black no-scroll-bar overflow-y-scroll overflow-hidden h-auto max-h-100 lg:max-h-auto bg-white rounded-lg flex flex-col p-7 flex flex-col z-90  overflow-y-scroll overflow-hidden h-auto max-h-100 ">
                 <div class=" rounded p-2 flex flex-col z-50">
            <LoadingSpinner v-if="isLoading" />
            <div v-else>
                <!-- <h1 class="text-2xl font-semibold text-gray-700">Company Profile Picture</h1> -->
                <ImagePoiInput @submit="submitPhoto" @close="closePhoto" />

            </div>
            
        </div>
     </div>
    </Modal>
</template>
<script>
/* eslint-disable no-unreachable */
import { useStore } from 'vuex'
import { reactive, ref, computed } from 'vue'
import ImagePoiInput from '@/components/inputs/ImageProfilePoiInput'
import Modal from '@/components/widgets/Modal'
import LoadingSpinner from '@/components/widgets/LoadingSpinner'
import { PhotoAlbumNames } from '@/constants'
export default {
    name: "CompanyPhotoAdd",
    components: { ImagePoiInput, Modal, LoadingSpinner },
    data() {
        return {
            imageFile: "",
            companyUrl: "",
            cropFile: "",
            file:""
        }
    },
    setup(props, { emit }) {
        const store = useStore()
        store.dispatch("fetchUserAlbum", PhotoAlbumNames.ProfilePhotos) // Fetch profile pictures
        const form = reactive({
            image: {
                preview: store.getters.getProfilePicture,
                image: null,
                coordinates: {
                    width: 0,
                    height: 0,
                    left: 0,
                    top: 0
                }
            },
            professionalName: store.getters.getUserDisplayProfessionalName,
            givenName: !store.getters.getUserDisplayProfessionalName
        })
        const submitActive = ref(false)
        const fileToBig = ref(false)
        const imagePoiSelectorOn = ref(false)
        function closePhotoWidget() {
            form.image = {
                preview: store.getters.getProfilePicture,
                image: null,
                coordinates: {
                    width: 0,
                    height: 0,
                    left: 0,
                    top: 0
                }
            }
            // imagePoiSelectorOn.value = false
            emit('close')
        }
        function saveImageData(file, croppedFile, crop) {
		
            var objectURL =(crop)?URL.createObjectURL(croppedFile):URL.createObjectURL(file);
            emit("passCompanyPhoto", { companyUrl: objectURL, imageFile: croppedFile, cropFile: crop,file:file });
            emit('close')
        }
        return {
            closePhotoWidget,
            store,
            form,
            submitActive,
            imagePoiSelectorOn,
            fileToBig,
            isLoading: computed(() => store.getters.isLoading),
            async submitPhoto({ file, crop, croppedFile, }) {

                if (file || croppedFile) {
                    // await store.dispatch("createCompanyMediaImage",
                    //      { file,
                    //        crop,
                    //        croppedFile,
                    //        logo: true,
                    //        profileId: store.getters.getId,
                    //        album: PhotoAlbumNames.ProfilePhotos
                    //     })
                    saveImageData(file, croppedFile, crop)
                    closePhotoWidget()


                }
            },async closePhoto(){
                closePhotoWidget()
            }

        }
    }
}
</script>
