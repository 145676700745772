<template>
  <div class="mt-10 lg:mx-56 sm:mx-10 mx-4">

    <div class="shadow sm:rounded-md sm:overflow-hidden">

      <!-- start -->
      <div class="overflow-hidden bg-white dark:bg-primaryDark md:p-10 p-5">

        <div class="bg-white dark:bg-primaryDark">

          <h2 class="text-2xl font-normal	 text-black dark:text-white"> Add company</h2>
          <p class="mt-5 text-xs dark:text-gray-300 leading-5"> Please fill in all relevant information for your company. Do not fill in the "Date dissolved" unless you are registering a company for historical reasons..</p>
        </div>
        <form class="md:w-full lg:w-full" @submit.prevent="onSubmit" ref="conversations">
          <div class="lg:pl-0 lg:pr-0 bg-white mt-8 xl:mt-14 dark:bg-primaryDark ">

            <h2 class="text-xs font-bold dark:text-white">
              Basic information
            </h2>

            <div class="flex mt-4 w-full md:flex-row flex-col gap-2 lg:gap-6 xl:gap-10">
              <div class="md:w-4/12 w-12/12 flex flex-col gap-5">
                <SmallTextInput heading="Company Name " :tooltip="true" :requiredIndicator="true" toolText="Add company name" class="col-span-6 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  :value="state.name" @update:value="state.name = $event" :errors="v$.name.$errors" ref="company_name" :focus="true" />

                <DateInput class="col-span-3" heading="Date Founded" :tooltip="true" :requiredIndicator="true" toolText="Add date founded"
                  :value="state.founded" @update:value="state.founded = $event" :errors="v$.founded.$errors" />

                <SmallTextInput heading="Place founded" class="col-span-6 " :requiredIndicator="true" :tooltip="true"
                  toolText="Add place founded" :value="state.place" @update:value="state.place = $event"
                  :errors="v$.place.$errors" />

              </div>
              <div class="md:w-4/12 flex flex-col gap-5">
                <SmallTextInput heading="English Name" class="col-span-6" :tooltip="true" toolText="Add english name"
                  :value="state.englishName" @update:value="state.englishName = $event" />

                <DateInput class="col-span-3" heading="Date Dissolved" :tooltip="true" toolText="Add date dissolved"
                  :value="state.dissolved" @update:value="state.dissolved = $event"  />

                <p class="text-sm flex items-center dark:text-white">
                  <SelectInput heading="Company Type" :tooltip="true" :requiredIndicator="true" textFormate="capitalize"
                    toolText="Select company type" class="col-span-6 sm:col-span-3 w-full gap-1"
                    @update:value="state.type = $event" :value="state.type" :options="companyTypes"
                    :errors="v$.type.$errors" />
                </p>
              </div>


              <div class="md:w-4/12 w-12/12 flex flex-col gap-4">
                <p class="text-sm flex items-center dark:text-white gap-2 text-sm font-medium text-gray-700">
                  Profile Picture
                  <BaseToolTip text="Add company Profile Picture here"> </BaseToolTip>
                </p>
                <div v-show="this.companyIn ==''" @click="imagePoiSelectorOn=!imagePoiSelectorOn"
                  :src="profilePicture ? profilePicture : ''"
                  class="bg-gray-200 text-gray-400 width h-28 w-28 flex items-center justify-center text-center p-3 rounded-full">
                  Add picture
                </div>
                <div v-show="this.companyIn !=''"
                  class=" relative bg-gray-200 width h-28 w-28 flex items-center justify-center overflow-hidden rounded-full"
                  @click="imagePoiSelectorOn=!imagePoiSelectorOn" :src="profilePicture ? profilePicture : ''">
                  <img class="h-auto w-72 absolute object-cover" :src="this.companyIn.companyUrl"
                    alt="Header image of Shakespere on Ice">
                  <!-- <p class=" inset-0 z-10 text-center"> Add <br>picture</p> -->
                </div>

                <SelectInput heading="Company Level" :tooltip="true" :requiredIndicator="true" textFormate="capitalize"
                  toolText="Select company level" class="col-span-6  gap-1 sm:col-span-3"
                  @update:value="state.level = $event" :value="state.level" :options="companyLevels"
                  :errors="v$.level.$errors" />
              </div>
            </div>


            <div class="mt-8 xl:mt-14 bg-white dark:bg-primaryDark grid-cols-x-contact">
              <div class="flex items-center mb-4 mr-12">
                <p class=" whitespace-nowrap pr-2 font-bold text-sm dark:text-white  ">Contact information</p>
                <!-- <div class="h-px w-full bg-gray-400"></div> -->
              </div>
              <div class="flex gap-2 mt-4 dark:text-white ">
                <PhoneIcon class="w-6 ml-1 transform text-gray-500 dark:text-white" />
                <p class="font-sm">Phone numbers</p>
                <BaseToolTip text="Add phone number"> </BaseToolTip>
              </div>
              <div class="pl-0 md:pl-8 dark:text-white">
                <ContactInfoDynamicForm @add-new-row="addNewPhoneRow" @delete-row="onPhoneDelete"
                  @onInput="onPhoneChange" :items="state.phoneNumbers" :keys="phoneKeys"
                  :errors="v$.phoneNumbers.$errors" :type="['number','text']" textFormate="capitalize" :labels="['Phone number', 'Phone label']"
                  title="Phone Number">
                </ContactInfoDynamicForm>
              </div>
              <div class="flex mt-8 gap-2 dark:text-white">
                <MailIcon class="w-6 transform text-gray-500 dark:text-white" />
                <p class="font-sm">Email addresses</p>
                <BaseToolTip text="Add email here"> </BaseToolTip>
              </div>
              <div class="pl-0 md:pl-8 dark:text-white">
                <ContactInfoDynamicForm @add-new-row="addNewEmailRow" @delete-row="onEmailDelete"
                  @onInput="onEmailChange" :items="state.emails" :keys="emailKeys" :errors="v$.emails.$errors"
                  :labels="['Email address', 'Email label']" :type="['email','text']" textFormate="capitalize" title="Email">
                </ContactInfoDynamicForm>
              </div>
              <div class="flex mt-10 gap-2 dark:text-white">
                <GlobeAltIcon class="w-6  transform text-gray-500 dark:text-white" />
                <p class="font-sm ">Web addresses</p>
                <BaseToolTip text="Add web addresses"> </BaseToolTip>

              </div>
              <div class="pl-0 md:pl-8 dark:text-white">
                <ContactInfoDynamicForm @add-new-row="addNewWebsiteRow" @delete-row="onWebsiteDelete"
                  @onInput="onWebsiteChange" :items="state.websites" textFormate="capitalize" :keys="websiteKeys"
                  :errors="v$.websites.$errors" :type="['text','text']" :labels="['Website address', 'Website label']" title="Website">

                </ContactInfoDynamicForm>
              </div>
            </div>
            <div class="flex mt-8 gap-2 items-center dark:text-white">
              <LocationMarkerIcon class="w-6 transform text-gray-500 dark:text-white" />
              <p class="font-sm">Addresses</p>
              <BaseToolTip text="Add email here"> </BaseToolTip>
            </div>

            <div class="pl-0 md:pl-8">
              <CompanyAddressInfoForm :hideButton="true" textFormate="capitalize"
                class="mt-2 bg-white dark:bg-primaryDark" />
            </div>
            <div class="flex items-center mb-4">
              <p class=" whitespace-nowrap pl-10 pr-2 py-2 font-semibold"></p>
              <!-- <div id="line" class="h-px w-full mr-12 bg-gray-400 "></div> -->
            </div>
            <div class="flex items-center gap-2 dark:text-white">
              <StatusOnlineIcon class="w-6 transform text-gray-500 dark:text-white" />
              <p class="font-sm ">Social Media Handles</p>
              <BaseToolTip text="Add social media"> </BaseToolTip>
            </div>
            <div class="pl-0 md:pl-8 dark:text-white">
              <CompanySocialForm class="mt-2 bg-white dark:bg-primaryDark" />
            </div>
          </div>

          <div class="px-4 py-3 bg-white text-right sm:px-6 dark:text-white dark:bg-primaryDark">
            <button @click="this.$router.push({ path: 'companies'})" type="button"
              class="mr-2 py-2 px-8 shadow-sm rounded-md sm:text-base font-medium text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1">
              <div>Cancel</div>
            </button> <button v-on:click="submit"  :disabled="this.isDisabled == 1" type="submit"
              class="shadow-sm py-2 px-8 rounded-md sm:text-base text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark font-medium ">
              Save
            </button>
          </div>


        </form>
      </div>
    </div>
    <CompanyPhotoAdd :modalActive="imagePoiSelectorOn" @passCompanyPhoto="GetCompanyImage($event)"
      :imagePoiSelectorOn="imagePoiSelectorOn" @close="setIsPhotoEditOpen(false)">


    </CompanyPhotoAdd>

    <div>

    </div>

  </div>

</template>

<script>
// Component for editing and saving company profile information
import { useStore, mapGetters } from "vuex";
import { computed, reactive, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import SmallTextInput from "@/components/inputs/SmallTextInput";
import SelectInput from "@/components/inputs/SelectInput";
import DateInput from "@/components/inputs/DateInput";
import CompanyPhotoAdd from "@/components/companies/components/companyHeader/CompanyPhotoAdd";
import BaseToolTip from "@/components/widgets/BaseToolTip.vue";
import ContactInfoDynamicForm from "@/components/forms/ContactInfoDynamicForm";
import { websiteValidator, emailValidator } from '@/utils/VuelidateValidators';
import { GlobeAltIcon, MailIcon, PhoneIcon } from "@heroicons/vue/outline/esm";
import CompanyAddressInfoForm from "@/components/forms/CompanyAddressInfoForm";
import CompanySocialForm from "@/components/forms/CompanySocialForm";
// import { notify } from "@kyvg/vue3-notification";
import { LocationMarkerIcon, StatusOnlineIcon } from "@heroicons/vue/outline/esm";
import { PhotoAlbumNames } from '@/constants'
import { createToaster } from "@meforma/vue-toaster";


export default {
  name: "CompanyInfo",
  data() {
    return {
      editActive: true,
      updatedWebsites: [],
      input: null,
      companyIn: "",
      files: null,
      cropFile: "",
      imageFile: "",
      logo: "",
      companyId: "",
      album: "",
      isDisabled: 0,
      disableBtnFlag: false,
      profilePicture:"",
      fileData:[]
    };
  },
  computed: {
    ...mapGetters(["getCompanyPhoneNumbers"]),

  },
  props: {
    imageUrl: String

  },

  components: {
    DateInput,
    SelectInput,
    CompanyPhotoAdd,
    SmallTextInput,
    BaseToolTip,
    ContactInfoDynamicForm,
    LocationMarkerIcon,
    StatusOnlineIcon,
    MailIcon, PhoneIcon, GlobeAltIcon, CompanyAddressInfoForm, CompanySocialForm,
  },

  setup() {
   
    const store = useStore();
    store.commit('setCompanyAddresses',[])
    store.commit('setCompanyPhoneNumbers',[])
    store.commit('setCompanyEmails',[])
    store.commit('setCompanyWebsites',[])
    store.commit('setCompanySocials',[])
    const currentCompany = store.getters.getCompany;
    const imagePoiSelectorOn = ref(false)
    const state = reactive({
      name: currentCompany.name,
      englishName: currentCompany.englishName,
      bio: '',
      founded: currentCompany.founded,
      dissolved: currentCompany.dissolved,
      type: currentCompany.type,
      level: currentCompany.level,
      logo: currentCompany.logo,
      phoneNumbers: computed(() => store.getters.getCompanyPhoneNumbers),
      emails: computed(() => store.getters.getCompanyEmails),
      websites: computed(() => store.getters.getCompanyWebsites),
      addresses: computed(() => store.getters.getCompanyAddresses),
      socials: computed(() => store.getters.getCompanySocials),

    });
    const rules = {
      name: { required },
      type: { required },
      level: { required },
      founded: { required },
      // dissolved: { required },
      place:{required},
      emails: {
        hasMail: emailValidator({
          email: { email },
        })
      },
      websites: {
        hasUrl: websiteValidator({
          url: { required }
        })
      },
      phoneNumbers: {}

    };

    console.log();
    const logoPreview = ref("");
    const v$ = useVuelidate(rules, state, { $lazy: true });

    return {
      v$,
      state,
      store,
      logoPreview,
      imagePoiSelectorOn,
      companyTypes: computed(() => store.getters.getCompanyTypes),
      companyLevels: computed(() => store.getters.getCompanyLevels),
      countries: computed(() => store.getters.getCountries),
      addresses: computed(() => store.getters.getCompanyAddresses),


      setIsPhotoEditOpen(value) {
        imagePoiSelectorOn.value = value
      },
      // ==========PHONE==========
      addNewPhoneRow: () => store.commit('addNewCompanyPhoneNumber'),
      async onPhoneChange(value, key, idx) {
        store.commit('editCompanyPhoneByIdx', { value, key, idx })
      },
      async onPhoneDelete(idx) {
        const result = confirm("Are you sure you want to delete?")
        try {
          const phone = store.getters.getCompanyPhoneByIdx(idx)
          if (phone.id && result) {
            await store.dispatch('deleteCompanyPhoneNumber', phone.id)
          }
          store.commit('removeCompanyPhoneByIdx', idx)
        } catch (e) {
          console.error(e)
        }
      },
      phoneKeys: computed(() => ['phoneNumber', 'label']),
      // ==========WEBSITE==========
      addNewWebsiteRow: () => store.commit('addNewCompanyWebsite'),
      async onWebsiteChange(value, key, idx) {
        store.commit('editCompanyWebsiteByIdx', { value, key, idx })
      },
      async onWebsiteDelete(idx) {
        const result = confirm("Are you sure you want to delete?")
        try {
          const website = store.getters.getCompanyWebsiteByIdx(idx)
          if (website.id && result) {
            await store.dispatch('deleteCompanyWebsite', website.id)
          }
          store.commit('removeCompanyWebsiteByIdx', idx)
        } catch (e) {
          console.error(e)
        }
      },
      websiteKeys: computed(() => ['url', 'title']),
      // ==========EMAIL==========
      addNewEmailRow: () => store.commit('addNewCompanyEmail'),
      async onEmailChange(value, key, idx) {
        store.commit('editCompanyEmailByIdx', { value, key, idx })
      },
      async onEmailDelete(idx) {
        const result = confirm("Are you sure you want to delete?")
        try {
          const email = store.getters.getCompanyEmailByIdx(idx)
          if (email.id && result) {
            await store.dispatch('deleteCompanyEmail', email.id)
          }
          store.commit('removeCompanyEmailByIdx', idx)
        } catch (e) {
          console.error(e)
        }
      },
      emailKeys: computed(() => ['email', 'label']),
      // ==========ADDRESS==========
      addNewAddressRow: () => store.commit('addNewAddress'),
      async onAddressChange(value, key, idx) {
        console.log('TO BE IMPLEMENTED', value, key, idx)
      },
      async onAddressDelete(idx) {
        console.log('TO BE IMPLEMENTED', idx)
      },
      addressItems: computed(() => store.getters.addresses),
      addressKeys: computed(() =>
        store.getters.addresses.length > 0
          ? Object.keys(store.getters.addresses[0]).filter(key => key !== 'id')
          : []
      ),
     
    };
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    focusInput() {
      this.$refs.company_name.$el.focus();
    },
    GetCompanyImage(data) {
     this.companyIn = data;
      this.state.logo = data.imageFile
      this.files =data.file
      this.cropFile = (data.cropFile)?true:false
      this.imageFile=data.imageFile;
   },
    async onSubmit() {
       
      // console.log( this.state)
      this.v$.$touch()
      const toast = createToaster({position:"top-right"});
    
      console.log(this.v$)
      if (this.v$.$error) {
      //   window.scrollTo(0, this.$refs.top.offsetTop)
        // notify({
        //   title: "Something went wrong",
        //   text: "Some required fields were not provided",
        //   type: 'warn',
        //   duration: 2000
        // })
        toast.error(`Some required fields were not provided`);
        this.$nextTick(() => {
            let scrollHeight = this.$refs.messages
            window.scrollTo(0, scrollHeight)
        })
        return false;
      }
      this.isDisabled=1;
      toast.success(`Company has been created successfully`);
      // notify({
      //   text: " 🎉",
      //   type: "success",
      //   duration: 2000
      // })
     
      await this.$store.dispatch('createCompany', this.state)    
      this.fileData['crop'] = this.cropFile;  
      this.fileData['file'] = this.files; 
      this.fileData['logo'] = true; 
      this.fileData['album'] = PhotoAlbumNames.ProfilePhotos
      this.fileData['companyId'] =this.$store.getters.getCompanyId;
      this.fileData['croppedFile']=this.imageFile
      // console.log(this.fileData)
      await this.$store.dispatch("createCompanyMediaImage", this.fileData)
      await this.$store.commit('removeCompanyEmailErrorProperty')
      await this.$store.commit('removeCompanyAddressErrorProperty')
      await this.$store.dispatch('saveCompanyPhoneNumbers')
      await this.$store.dispatch('saveCompanyWebsites')
      await this.$store.dispatch('saveCompanyEmails')
      await this.$store.dispatch('saveCompanyAddresses')
      await this.$store.dispatch('saveCompanySocials')
      await this.$router.push({ name: 'Company', params: { id: this.$store.getters.getCompanyId }})
    }
    
  }
};
</script>
